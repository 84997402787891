import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const DWRNavigationHeader = ({ 
  selectedDwr, 
  formData, 
  handleNavigate, 
  isNavigating, 
  getAdjacentDWR 
}) => {
  if (!selectedDwr) {
    return (
      <Box sx={{ 
        padding: '16px 0',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '24px'
      }}>
        <Typography variant="h6">
          New DWR
        </Typography>
      </Box>
    );
  }

  const formattedDate = formData.date 
    ? new Date(formData.date).toLocaleDateString("en-US", { 
        timeZone: "UTC",
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : 'No Date';

  const dayText = formData.day ? ` - Day ${formData.day}` : '';

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 0',
      borderBottom: '1px solid #e0e0e0',
      marginBottom: '24px',
      backgroundColor: '#fff'
    }}>
      <Button
        startIcon={<ArrowBackIosNewIcon />}
        onClick={() => handleNavigate('prev')}
        disabled={isNavigating || !getAdjacentDWR || !getAdjacentDWR(selectedDwr, 'prev')}
        sx={{
          visibility: getAdjacentDWR ? 'visible' : 'hidden',
          minWidth: '100px',
          '&.Mui-disabled': {
            visibility: 'hidden'
          }
        }}
      >
        Previous
      </Button>
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 500,
          color: '#1a1a1a',
          textAlign: 'center',
          flexGrow: 1,
          mx: 2
        }}
      >
        {formattedDate}{dayText}
      </Typography>
      <Button
        endIcon={<ArrowForwardIosIcon />}
        onClick={() => handleNavigate('next')}
        disabled={isNavigating || !getAdjacentDWR || !getAdjacentDWR(selectedDwr, 'next')}
        sx={{
          visibility: getAdjacentDWR ? 'visible' : 'hidden',
          minWidth: '100px',
          '&.Mui-disabled': {
            visibility: 'hidden'
          }
        }}
      >
        Next
      </Button>
    </Box>
  );
};

export default DWRNavigationHeader;