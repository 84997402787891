import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../api/AuthContext';
import { useLocation } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import axios from 'axios';
import '../styles/ChargeRecord.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const appUrl = process.env.REACT_APP_APP_URL;
if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

function ChargeRecord({
  inventoryItems,
  serviceItems,
  onTurnkeyChargeItems,
  offTurnkeyChargeItems,
  setOnTurnkeyChargeItems,
  setOffTurnkeyChargeItems,
  quantities,
  updateQuantities,
  showDuplicateError,
  onUpdateChargeRecords,
  selectedDwr,
  disableUpdate,
  well,
  chargeRecordIds,
  setZeroAllPrices,
  // currentPricebook,
  currentInventoryPricebook,
  currentServicePricebook,
  isLoadingPricebook,
  disabled
}) {
  const { authState } = useAuth();
  const currentUser = authState?.user;
  const token = authState?.token;
  const location = useLocation();
  const [totalPriceOnTurnkey, setTotalPriceOnTurnkey] = useState(0);
  const [totalPriceOffTurnkey, setTotalPriceOffTurnkey] = useState(0);

  const calculateTotalPrice = (items) => {
    return items.reduce((acc, item) => acc + (parseFloat(item.quantity_used) * getItemPrice(item)), 0);
  };

  // const getItemPrice = useCallback((item) => {
  //   if (!currentPricebook || isLoadingPricebook) return item.price;

  //   const pricebookItem = currentPricebook.find(
  //     pbi => (item.type === 'Inventory' ? pbi.inventory_item : pbi.service_item) === item.id
  //   );

  //   return pricebookItem ? pricebookItem.price : item.price;
  // }, [currentPricebook, isLoadingPricebook]);

  // Update getItemPrice function
  const getItemPrice = useCallback((item) => {
    if (isLoadingPricebook) return item.price;

    if (item.type === 'Inventory') {
      if (!currentInventoryPricebook) return item.price;
      const pricebookItem = currentInventoryPricebook.find(
        pbi => pbi.inventory_item === item.id
      );
      return pricebookItem ? pricebookItem.price : item.price;
    }

    if (item.type === 'Service') {
      if (!currentServicePricebook) return item.price;
      const pricebookItem = currentServicePricebook.find(
        pbi => pbi.service_item === item.id
      );
      return pricebookItem ? pricebookItem.price : item.price;
    }

    return item.price;
  }, [currentInventoryPricebook, currentServicePricebook, isLoadingPricebook]);

  // Add pricing system indicator
const renderPricingSystemIndicator = () => (
  <Typography variant="caption" color="textSecondary">
    Using {well.creation_date >= process.env.REACT_APP_SERVICE_PRICEBOOK_CUTOFF_DATE 
      ? 'new' 
      : 'legacy'} pricing system
  </Typography>
);


  const handleDeleteChargeItem = useCallback(async (item, type) => {
    const itemsArray = type === 'onTurnkey' ? onTurnkeyChargeItems : offTurnkeyChargeItems;
    const setItemsArray = type === 'onTurnkey' ? setOnTurnkeyChargeItems : setOffTurnkeyChargeItems;
    const chargeRecordId = chargeRecordIds[type];
    if (!chargeRecordId) {
      console.error(`No charge record ID found for ${type}`);
      return;
    }

    const isInventoryItem = item.type === 'Inventory';

    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this charge item?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              // Step 1: Delete the individual item
              const endpoint = isInventoryItem ? 'inventoryitemcharges' : item.type === 'Service' ? 'serviceitemcharges' : 'miscellaneousitemcharges';
              await axios.delete(`${appUrl}/jmrapp/${endpoint}/${item.chargeId}/`, {
                headers: { 'Authorization': `Bearer ${token}` },
              });

              // Step 2: Update the local state
              const updatedItems = itemsArray.filter(i => i.chargeId !== item.chargeId);
              setItemsArray(updatedItems);

              // Step 3: Update the entire charge record
              if (chargeRecordId) {
                await axios.patch(`${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`, {
                  service_item_charges: updatedItems
                    .filter(i => i.type === 'Service')
                    .map(i => ({
                      id: i.chargeId,
                      service_item_id: i.id,
                      quantity_used: i.quantity_used,
                    })),
                  inventory_item_charges: updatedItems
                    .filter(i => i.type === 'Inventory')
                    .map(i => ({
                      id: i.chargeId,
                      inventory_item_id: i.id,
                      quantity_used: i.quantity_used,
                    })),
                  miscellaneous_item_charges: updatedItems
                    .filter(i => i.type === 'Miscellaneous')
                    .map(i => ({
                      id: i.chargeId,
                      miscellaneous_item_id: i.id,
                      quantity_used: i.quantity_used,
                      custom_name: i.custom_name,
                      price_at_use: parseFloat(i.price_at_use),
                    })),
                }, {
                  headers: { 'Authorization': `Bearer ${token}` },
                });
                console.log('Charge item successfully deleted and charge record updated');
              } else {
                console.error('No charge record ID found for', type);
              }
            } catch (error) {
              console.error('Error processing deletion:', error);
              if (error.response && error.response.status === 404) {
                console.warn('Charge item not found in backend, removing from local state:', item.chargeId);
                setItemsArray(prevItems => prevItems.filter(i => i.chargeId !== item.chargeId));
              } else {
                alert('An error occurred while deleting the charge item. Please try again.');
              }
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }, [onTurnkeyChargeItems, offTurnkeyChargeItems, setOnTurnkeyChargeItems, setOffTurnkeyChargeItems, chargeRecordIds, appUrl, token]);

  const handleQuantityChange = useCallback(
    debounce((changedItem, newQuantityUsed, type) => {
      const numericQuantityUsed = Number(newQuantityUsed);
      const updateItems = (prevItems) =>
        prevItems.map(item => {
          if (item.id === changedItem.id && item.type === changedItem.type) {
            return {
              ...item,
              quantity_used: numericQuantityUsed,
              total: numericQuantityUsed * (item.price || item.price_at_use),
            };
          }
          return item;
        });
      if (type === 'onTurnkey') {
        setOnTurnkeyChargeItems(updateItems);
      } else {
        setOffTurnkeyChargeItems(updateItems);
      }
      updateQuantities(changedItem.id, numericQuantityUsed);
    }, 500),
    [setOnTurnkeyChargeItems, setOffTurnkeyChargeItems, updateQuantities]
  );

  const handleCustomNameChange = useCallback((changedItem, newCustomName, type) => {
    const updateItems = (prevItems) =>
      prevItems.map(item => {
        if (item.id === changedItem.id && item.type === 'Miscellaneous') {
          return { ...item, name: newCustomName, custom_name: newCustomName };
        }
        return item;
      });

    if (type === 'onTurnkey') {
      setOnTurnkeyChargeItems(updateItems);
    } else {
      setOffTurnkeyChargeItems(updateItems);
    }
  }, [setOnTurnkeyChargeItems, setOffTurnkeyChargeItems]);

  const handleCustomPriceChange = useCallback((changedItem, newCustomPrice, type) => {
    const numericCustomPrice = Number(newCustomPrice);
    const updateItems = (prevItems) =>
      prevItems.map(item => {
        if (item.id === changedItem.id && item.type === 'Miscellaneous') {
          return { ...item, price: numericCustomPrice, price_at_use: numericCustomPrice };
        }
        return item;
      });

    if (type === 'onTurnkey') {
      setOnTurnkeyChargeItems(updateItems);
    } else {
      setOffTurnkeyChargeItems(updateItems);
    }
  }, [setOnTurnkeyChargeItems, setOffTurnkeyChargeItems]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    if (!selectedDwr) {
      console.error("No DWR selected, cannot update charge records.");
      return;
    }
    console.log(`Submitting charge records update for DWR ${selectedDwr.id}`);
    console.log('Current chargeRecordIds:', chargeRecordIds);

    if (chargeRecordIds.onTurnkey) {
      onUpdateChargeRecords('onTurnkey');
    } else {
      console.warn('No onTurnkey charge record ID available');
    }

    if (chargeRecordIds.offTurnkey) {
      onUpdateChargeRecords('offTurnkey');
    } else {
      console.warn('No offTurnkey charge record ID available');
    }
  }, [onUpdateChargeRecords, selectedDwr, chargeRecordIds]);

  const renderChargeItems = useCallback((items, type) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item Type</TableCell>
            <TableCell>Item Name</TableCell>
            <TableCell>Unit Type</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Quantity Used</TableCell>
            <TableCell>Quantity Available</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Delete Item</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => {
            const price = type === 'onTurnkey' ? 0 : getItemPrice(item);
            const currentQuantity = quantities[item.id] || item.quantity_used;
            const total = currentQuantity * price;

            return (
              <TableRow key={item.id}>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  {item.type === 'Miscellaneous' ? (
                    <TextField
                      value={item.name}
                      onChange={(e) => handleCustomNameChange(item, e.target.value, type)}
                      disabled={disabled}
                    />
                  ) : (
                    item.name
                  )}
                </TableCell>
                <TableCell>{item.type === 'Miscellaneous' ? 'Each' : item.unit_type}</TableCell>
                <TableCell>
                  {type === 'onTurnkey' ? (
                    '$0.00'
                  ) : item.type === 'Miscellaneous' ? (
                    <TextField
                      type="number"
                      value={item.price}
                      onChange={(e) => handleCustomPriceChange(item, e.target.value, type)}
                      disabled={disabled}

                    />
                  ) : (
                    `$${Number(price).toFixed(2)}`
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={item.quantity_used.toString()}
                    onChange={(e) => handleQuantityChange(item, e.target.value, type)}
                    inputProps={{ min: "0", step: "1" }}
                    disabled={disabled}

                  />
                </TableCell>
                <TableCell>{item.quantity_available}</TableCell>
                <TableCell>${Number(total).toFixed(2)}</TableCell>
                <TableCell>
                  <Button onClick={() => handleDeleteChargeItem(item, type)} disabled={disabled}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={6}>Total</TableCell>
            <TableCell>
              ${items.reduce((acc, item) => {
                const itemPrice = type === 'onTurnkey' ? 0 : getItemPrice(item);
                return acc + (Number(item.quantity_used) * Number(itemPrice));
              }, 0).toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }, [handleQuantityChange, handleDeleteChargeItem, quantities, getItemPrice]);

  return (
    <>
      {showDuplicateError && (
        <div style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
          Duplicate charge items are not allowed.
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <h3>On Turnkey Charges</h3>
        {renderChargeItems(onTurnkeyChargeItems, 'onTurnkey')}
        <h3>Off Turnkey Charges</h3>
        {renderChargeItems(offTurnkeyChargeItems, 'offTurnkey')}
        <TableRow>
          <TableCell colSpan={6}>Overall Total</TableCell>
          <TableCell>
            ${(calculateTotalPrice(offTurnkeyChargeItems)).toFixed(2)}
          </TableCell>
        </TableRow>
        {selectedDwr && (
          <Button 
            className='btn hover' 
            onClick={handleSubmit}
            disabled={disableUpdate}
            style={{ marginTop: '20px' }}
          >
            Update Charge Records
          </Button>
        )}
      </form>
    </>
  );
}

ChargeRecord.propTypes = {
  inventoryItems: PropTypes.array.isRequired,
  serviceItems: PropTypes.array.isRequired,
  onTurnkeyChargeItems: PropTypes.array.isRequired,
  offTurnkeyChargeItems: PropTypes.array.isRequired,
  setOnTurnkeyChargeItems: PropTypes.func.isRequired,
  setOffTurnkeyChargeItems: PropTypes.func.isRequired,
  quantities: PropTypes.object.isRequired,
  updateQuantities: PropTypes.func.isRequired,
  showDuplicateError: PropTypes.bool.isRequired,
  onUpdateChargeRecords: PropTypes.func.isRequired,
  selectedDwr: PropTypes.object,
  disableUpdate: PropTypes.bool.isRequired,
  well: PropTypes.object.isRequired,
  // currentPricebook: PropTypes.array,
  currentInventoryPricebook: PropTypes.array,
  currentServicePricebook: PropTypes.array,
  isLoadingPricebook: PropTypes.bool.isRequired,
  well: PropTypes.object.isRequired,

};

export default ChargeRecord;
