import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Checkbox, FormControlLabel, Button, TextField, Typography, Tabs, Tab } from '@mui/material';
import axios from 'axios';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto'
};

const ChargeRecordModal = ({ 
  open, 
  handleClose, 
  onChargeRecordSelect, 
  well,
  type,
  disabled 
}) => {
  const [selectedInventoryIds, setSelectedInventoryIds] = useState([]);
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [selectedMiscIds, setSelectedMiscIds] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [items, setItems] = useState({
    inventory: [],
    services: [],
    miscellaneous: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const isNewPricingSystem = well?.creation_date >= process.env.REACT_APP_SERVICE_PRICEBOOK_CUTOFF_DATE;

  useEffect(() => {
    const fetchItems = async () => {
      setIsLoading(true);
      setError(null);
      let retryCount = 0;
      const maxRetries = 3;
      const fetchWithRetry = async () => {
        try {
          const token = localStorage.getItem('my-jwt');
          
          // Fetch inventory items
          const inventoryResponse = await axios.get(
            `${process.env.REACT_APP_APP_URL}/jmrapp/active-inventory-items/`,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // Fetch service items with retry logic
          const fetchServiceItems = async () => {
            try {
              return await axios.get(
                `${process.env.REACT_APP_APP_URL}/jmrapp/new-service-pricebooks/`,
                { 
                  headers: { Authorization: `Bearer ${token}` },
                  params: { 
                    well_id: well.id,
                    date: well.creation_date
                  }
                }
              );
            } catch (error) {
              if (retryCount < maxRetries && error.response?.status === 500) {
                retryCount++;
                await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
                return fetchServiceItems();
              }
              throw error;
            }
          };

          const serviceResponse = await fetchServiceItems();

          // Transform service items to consistent format
          const transformedServiceItems = serviceResponse.data?.pricebook_data?.map(item => ({
            id: item.service_item?.id || item.id,
            name: item.service_item?.name || item.name,
            price: item.price,
            unit_type: item.service_item?.unit_type || item.unit_type,
            endpoint_id: item.service_item?.endpoint_id || item.endpoint_id
          })) || [];
          // Fetch miscellaneous items
          const miscResponse = await axios.get(
            `${process.env.REACT_APP_APP_URL}/jmrapp/active-miscellaneous-items/`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setItems({
            inventory: inventoryResponse.data,
            services: transformedServiceItems,
            miscellaneous: miscResponse.data
          });
        } catch (err) {
          setError(err.message);
          console.error('Error fetching items:', err);
        } finally {
          setIsLoading(false);
        }
      };

      if (open && well?.id) {
        fetchWithRetry();
      }
    };

    if (open && well?.id) {
      fetchItems();
    }
  }, [open, well]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = (event) => {
    setSearchString(event.target.value.toLowerCase());
  };

  // const filterItems = (items) => {
  //   return items.filter(item => 
  //     item.name.toLowerCase().includes(searchString) ||
  //     (item.description || '').toLowerCase().includes(searchString)
  //   );
  // };

  const handleSelectItem = (id, type) => {
    switch(type) {
      case 'inventory':
        setSelectedInventoryIds(prev => 
          prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
        );
        break;
      case 'service':
        setSelectedServiceIds(prev => 
          prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
        );
        break;
      case 'miscellaneous':
        setSelectedMiscIds(prev => 
          prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
        );
        break;
    }
  };

  const handleSubmit = () => {
    onChargeRecordSelect(
      selectedInventoryIds, 
      selectedServiceIds, 
      selectedMiscIds,
      type
    );
    handleClose();
  };

// Update the filterItems function:
const filterItems = (itemArray = []) => {
  if (!Array.isArray(itemArray)) return [];
  return itemArray.filter(item => {
    if (!item) return false;
    const itemName = item.name || '';
    const itemUnitType = item.unit_type || '';
    return itemName.toLowerCase().includes(searchString) || 
           itemUnitType.toLowerCase().includes(searchString);
  });
};
  
// Update the Box containing the items list to use flexbox in a column
const renderItemList = (itemArray, type) => {
  if (!Array.isArray(itemArray)) return null;
  
  const filteredItems = filterItems(itemArray);
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}>
      {filteredItems.map(item => (
        <FormControlLabel
          key={item.id}
          control={
            <Checkbox
              checked={
                type === 'inventory' ? selectedInventoryIds.includes(item.id) :
                type === 'service' ? selectedServiceIds.includes(item.id) :
                selectedMiscIds.includes(item.id)
              }
              onChange={() => handleSelectItem(item.id, type)}
              disabled={disabled}
            />
          }
          label={
            <Typography>
              {item.name} {item.unit_type ? `(${item.unit_type})` : ''}
            </Typography>
          }
          sx={{ width: '100%' }}
        />
      ))}
    </Box>
  );
};

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="charge-record-modal-title"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" gutterBottom>
          Select {type === 'onTurnkey' ? 'On Turnkey' : 'Off Turnkey'} Charges
          {isNewPricingSystem && 
            <Typography variant="caption" display="block">
              Using new pricing system
            </Typography>
          }
        </Typography>

        <TextField
          fullWidth
          label="Search"
          value={searchString}
          onChange={handleSearch}
          margin="normal"
        />

        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Inventory Items" />
          <Tab label="Service Items" />
          <Tab label="Miscellaneous Items" />
        </Tabs>

        {isLoading ? (
          <Typography>Loading items...</Typography>
        ) : error ? (
          <Typography color="error">Error: {error}</Typography>
        ) : (
          <Box sx={{ mt: 2 }}>
            {tabValue === 0 && renderItemList(items?.inventory || [], 'inventory')}
            {tabValue === 1 && renderItemList(items?.services || [], 'service')}
            {tabValue === 2 && renderItemList(items?.miscellaneous || [], 'miscellaneous')}
          </Box>

        )}

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            disabled={disabled || isLoading}
          >
            Add Selected
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ChargeRecordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onChargeRecordSelect: PropTypes.func.isRequired,
  well: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['onTurnkey', 'offTurnkey']).isRequired,
  disabled: PropTypes.bool
};

export default ChargeRecordModal;
