import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';
import '../styles/Well.css';
import WellForm from './WellForm';
import WellSummary from './WellSummary';
import DWR from './DWR';
import PriceBookView from './PriceBookView';
import axios from 'axios';
import SubProjects from './SubProjects';

const Well = () => {
  const { onLogout } = useAuth();
  const location = useLocation();
  const well = location.state?.well;
  const handleWellUpdated = location.state?.handleWellUpdated || (() => {});
  const [activeTab, setActiveTab] = useState('well');
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rigs, setRigs] = useState([]);
  const [managingFiles, setManagingFiles] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading set to true
  const [uploading, setUploading] = useState(false);
  const [selectedSubproject, setSelectedSubproject] = useState(null);
  const fileInputRef = useRef(null);
  const [showDWR, setShowDWR] = useState(false);

// Add debug logging to track the selected subproject
useEffect(() => {
  console.log('Selected subproject in Well.jsx:', selectedSubproject);
}, [selectedSubproject]);

const handleDWRClose = () => {
  setShowDWR(false);
  setSelectedSubproject(null);
};

const handleTabClick = (tab) => {
  if (tab === 'projects' && showDWR) {
    setShowDWR(false);
  } else {
    setActiveTab(tab);
    if (['safety', 'accounting', 'rrc', 'miscellaneous', 'procedures'].includes(tab)) {
      fetchFiles(tab === 'miscellaneous' ? 'misc' : tab);
    }
  }
};

  const fetchFiles = async (category) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${process.env.REACT_APP_APP_URL}/jmrapp/files/?well_id=${well.id}&doc_type=${category}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRigs = async () => {
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${process.env.REACT_APP_APP_URL}/jmrapp/rigs/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setRigs(response.data);
    } catch (error) {
      console.error('Error fetching rigs:', error);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    fetchRigs();
  }, []);

  useEffect(() => {
    if (['safety', 'accounting', 'rrc', 'miscellaneous', 'procedures'].includes(activeTab)) {
      fetchFiles(activeTab === 'miscellaneous' ? 'misc' : activeTab);
    }
  }, [activeTab, well]);

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('No file selected, please select a file using "choose file," then click "Upload File."');
      return;
    }

    setUploading(true);

    try {
      // Ensure rigs have been fetched
      if (rigs.length === 0) {
        console.error('Rigs not loaded yet');
        return;
      }

      const wellName = well.name || 'UnknownWell';
      const originalFileName = selectedFile.name;
      const newFileName = `${originalFileName}`;

      const formData = new FormData();
      const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });
      formData.append('file', renamedFile);
      formData.append('assigned_well', well.id);
      formData.append('doc_type', activeTab === 'miscellaneous' ? 'misc' : activeTab);

      const token = localStorage.getItem('my-jwt');
      await axios.post(`${process.env.REACT_APP_APP_URL}/jmrapp/upload/`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchFiles(activeTab === 'miscellaneous' ? 'misc' : activeTab);
      // Clear the file input and reset selectedFile state
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleFileDelete = async (fileId) => {
    try {
      const token = localStorage.getItem('my-jwt');
      await axios.delete(`${process.env.REACT_APP_APP_URL}/jmrapp/files/${fileId}/delete/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      fetchFiles(activeTab === 'miscellaneous' ? 'misc' : activeTab);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleFileSelection = (fileId) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(fileId)) {
        return prevSelectedFiles.filter((id) => id !== fileId);
      } else {
        return [...prevSelectedFiles, fileId];
      }
    });
  };

  const handleManageFiles = () => {
    setManagingFiles(!managingFiles);
  };

  const confirmDelete = () => {
    if (window.confirm('Are you sure you want to delete the selected files?')) {
      selectedFiles.forEach((fileId) => handleFileDelete(fileId));
      setSelectedFiles([]);
      setManagingFiles(false);
    }
  };

  const formatFileName = (url) => {
    if (!url) return 'Unknown File';
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  console.log("Well: ", well)

  return (
    <>
      <div className='items'>
        <ul className='list-tab'>
          <li className={activeTab === 'well' ? 'active' : ''} onClick={() => handleTabClick('well')}>Well Data</li>
          <li className={activeTab === 'projects' ? 'active' : ''} onClick={() => handleTabClick('projects')}>Projects</li>          
          <li className={activeTab === 'summary' ? 'active' : ''} onClick={() => handleTabClick('summary')}>Summary</li>
          <li className={activeTab === 'pricebook' ? 'active' : ''} onClick={() => handleTabClick('pricebook')}>Price Book</li>
          <li className={activeTab === 'safety' ? 'active' : ''} onClick={() => handleTabClick('safety')}>Safety</li>
          <li className={activeTab === 'accounting' ? 'active' : ''} onClick={() => handleTabClick('accounting')}>Accounting</li>
          <li className={activeTab === 'rrc' ? 'active' : ''} onClick={() => handleTabClick('rrc')}>RRC</li>
          <li className={activeTab === 'miscellaneous' ? 'active' : ''} onClick={() => handleTabClick('miscellaneous')}>Miscellaneous</li>
          <li className={activeTab === 'procedures' ? 'active' : ''} onClick={() => handleTabClick('procedures')}>Procedures</li>
        </ul>
      </div>

      {activeTab === 'well' && <WellForm well={well} onWellUpdated={handleWellUpdated} />}
      {activeTab === 'projects' && (
        <>
          {!showDWR ? (
            <SubProjects 
              well={well} 
              onSelectSubproject={(subproject) => {
                console.log('Subproject selected in Well.jsx:', subproject);
                setSelectedSubproject(subproject.subproject);
                setShowDWR(true);  // Add this to show DWR view when subproject is selected
              }} 
            />
          ) : (
            <DWR 
              wellId={well.id} 
              wellStatus={well.status}
              subprojectId={selectedSubproject.id}
              subprojectType={selectedSubproject.subproject_type}
              onClose={() => {
                setShowDWR(false);  // Hide DWR view
                setSelectedSubproject(null);  // Clear selected subproject
              }}
            />
          )}
        </>
      )}
      {activeTab === 'pricebook' && <PriceBookView well={well} />}
      {activeTab === 'summary' && <WellSummary well={well} />}


      {['safety', 'accounting', 'rrc', 'miscellaneous', 'procedures'].includes(activeTab) && (
        <div className="tab-content">
          <h2>{activeTab === 'rrc' ? activeTab.toUpperCase() : activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Files</h2>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {files.length === 0 ? (
                <div>No files</div>
              ) : (
                <ul>
                  {files.map((file) => (
                    <li key={file.id}>
                      {managingFiles && (
                        <input
                          type="checkbox"
                          checked={selectedFiles.includes(file.id)}
                          onChange={() => handleFileSelection(file.id)}
                        />
                      )}
                      <a href={file.file_url} target="_blank" rel="noopener noreferrer">{formatFileName(file.file_url)}</a>
                      <span> {new Date(file.uploaded_at).toLocaleString()}</span>
                    </li>
                  ))}
                </ul>
              )}
              <div className="file-upload">
                <input type="file" onChange={handleFileChange} ref={fileInputRef} />
                <button onClick={handleFileUpload} disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Upload File'}
                </button>
              </div>
            </>
          )}
          <div className="file-manage">
            <button onClick={handleManageFiles}>
              {managingFiles ? 'Cancel Delete' : 'Select Files to Delete'}
            </button>
            {managingFiles && (
              <button onClick={confirmDelete}>
                Delete Selected Files
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Well;
