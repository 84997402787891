import React, { useState, useEffect } from 'react';
import { useAuth } from '../api/AuthContext';
import axios from 'axios';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

const SubProjects = ({ well, onSelectSubproject }) => {
  const { authState } = useAuth();
  const [subprojects, setSubprojects] = useState([]);
  const [selectedSubproject, setSelectedSubproject] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [subprojectTypes, setSubprojectTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [sortOption, setSortOption] = useState('name');
  const [availableRigs, setAvailableRigs] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [newSubproject, setNewSubproject] = useState({
    name: '',
    start_date: '',
    assigned_rig: '',
    area: '',
    status: 'In Progress'
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [subprojectsResponse, typesResponse, rigsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_APP_URL}/jmrapp/subprojects/?project_id=${well.id}`, {
            headers: { Authorization: `Bearer ${authState.token}` }
          }),
          axios.get(`${process.env.REACT_APP_APP_URL}/jmrapp/subproject-types/`, {
            headers: { Authorization: `Bearer ${authState.token}` }
          }),
          axios.get(`${process.env.REACT_APP_APP_URL}/jmrapp/rigs/`, {
            headers: { Authorization: `Bearer ${authState.token}` }
          })
        ]);

        setSubprojects(subprojectsResponse.data);
        setSubprojectTypes(typesResponse.data);
        setAvailableRigs(rigsResponse.data);
      } catch (error) {
        console.error('Error fetching subproject data:', error);
      }
    };

    if (well?.id) {
      fetchData();
    }
  }, [well, authState.token]);

  const getSortedTypes = () => {
    return [...subprojectTypes].sort((a, b) => {
      switch (sortOption) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'nameDesc':
          return b.name.localeCompare(a.name);
        case 'hasProject':
          const aHasProject = subprojects.some(p => p.subproject_type === a.id);
          const bHasProject = subprojects.some(p => p.subproject_type === b.id);
          return bHasProject - aHasProject;
        case 'noProject':
          const aNoProject = !subprojects.some(p => p.subproject_type === a.id);
          const bNoProject = !subprojects.some(p => p.subproject_type === b.id);
          return bNoProject - aNoProject;
        default:
          return a.name.localeCompare(b.name);
      }
    });
  };

  const handleCreateClick = (type) => {
    setSelectedType(type);
    const timestamp = new Date().toISOString().split('T')[0];
    const existingCount = subprojects.filter(
      sp => sp.subproject_type === type.id
    ).length;
    const baseName = `${well.name} - ${type.name}`;
    const projectName = existingCount > 0 
      ? `${baseName} (${existingCount + 1})`
      : baseName;
    setNewSubproject({
      name: projectName,
      start_date: timestamp,
      assigned_rig: '',
      area: '',
      status: 'In Progress'
    });
    setIsCreateModalOpen(true);
  };

  const handleCreateSubproject = async () => {
    setIsCreating(true);
    try {
      const netsuiteResponse = await axios.post(
        `${process.env.REACT_APP_APP_URL}/proxyapp/create-subproject-netsuite/`,
        {
          name: newSubproject.name,
          parent_project_id: well.netsuite_id,
          start_date: newSubproject.start_date,
          subproject_type: selectedType.netsuite_id,
          assigned_rig: newSubproject.assigned_rig,
        },
        {
          headers: { Authorization: `Bearer ${authState.token}` }
        }
      );

      if (netsuiteResponse.data.id) {
        try {
          const localDbResponse = await axios.post(
            `${process.env.REACT_APP_APP_URL}/jmrapp/subprojects/`,
            {
              ...newSubproject,
              project: well.id,
              subproject_type: selectedType.id,
              netsuite_id: netsuiteResponse.data.id,
              assigned_rig: newSubproject.assigned_rig
            },
            {
              headers: { Authorization: `Bearer ${authState.token}` }
            }
          );

          setSubprojects([...subprojects, localDbResponse.data]);
          setIsCreateModalOpen(false);
          setSelectedType(null);
          setNewSubproject({
            name: '',
            start_date: '',
            assigned_rig: '',
            area: '',
            status: 'In Progress'
          });
          setSuccessMessage('Subproject created successfully!');
        } catch (localDbError) {
          console.error('Local DB creation failed:', localDbError);
          try {
            await axios.delete(
              `${process.env.REACT_APP_APP_URL}/proxyapp/delete_project/${netsuiteResponse.data.id}/`,
              {
                headers: { Authorization: `Bearer ${authState.token}` }
              }
            );
          } catch (deleteError) {
            console.error('Failed to delete NetSuite entity:', deleteError);
          }
          throw new Error(localDbError.response?.data?.non_field_errors?.[0] || 'Failed to create subproject in local database');
        }
      }
    } catch (error) {
      console.error('Error creating subproject:', error);
      let errorMessage = 'Failed to create subproject. ';
      if (error.response?.data?.non_field_errors) {
        errorMessage += error.response.data.non_field_errors[0];
      } else if (error.response?.data?.error) {
        errorMessage += error.response.data.error;
      } else if (error.message) {
        errorMessage += error.message;
      }
      alert(errorMessage);
    } finally {
      setIsCreating(false);
    }
  };

  const handleSubprojectSelect = (subproject) => {
    console.log('Subproject selected in SubProjects.jsx:', subproject);
    setSelectedSubproject(subproject);
    onSelectSubproject({ 
      subproject,
      action: 'view'
    });
  };
  
  const handleCreateDWR = (subproject) => {
    setSelectedSubproject(subproject);
    onSelectSubproject({ subproject, action: 'create_dwr' });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Project Types</Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            label="Sort By"
          >
            <MenuItem value="name">Name (A-Z)</MenuItem>
            <MenuItem value="nameDesc">Name (Z-A)</MenuItem>
            <MenuItem value="hasProject">Has Projects First</MenuItem>
            <MenuItem value="noProject">Available Projects First</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
      <Grid container spacing={3}>
        {getSortedTypes().map((type) => {
          const existingProject = subprojects.find(
            project => project.subproject_type === type.id
          );

          return (
            <Grid item xs={12} md={6} lg={4} key={type.id}>
              <Card>
                <CardHeader title={type.name} />
                <CardContent>
                  {existingProject ? (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={() => handleCreateDWR(existingProject)}
                        sx={{ ml: 1 }}
                      >
                        View DWRs
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => handleCreateClick(type)}
                      sx={{
                        mt: 2,
                        backgroundColor: '#0b6e4f',
                        '&:hover': { backgroundColor: '#095c42' }
                      }}
                    >
                      Create {type.name} Project
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Dialog open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
        <DialogTitle>Create New {selectedType?.name} Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Project Name"
            fullWidth
            value={newSubproject.name}
            onChange={(e) => setNewSubproject({ ...newSubproject, name: e.target.value })}
            sx={{ mt: 2 }}
            disabled={isCreating}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Assigned Rig</InputLabel>
            <Select
              value={newSubproject.assigned_rig}
              onChange={(e) => setNewSubproject({ ...newSubproject, assigned_rig: e.target.value })}
              label="Assigned Rig"
              disabled={isCreating}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {[...availableRigs]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((rig) => (
                  <MenuItem key={rig.id} value={rig.id}>
                    {rig.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Typography variant="caption" color="warning.main" sx={{ mt: 1, display: 'block' }}>
            Note: The assigned rig cannot be changed once the subproject is created
          </Typography>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={newSubproject.start_date}
            onChange={(e) => setNewSubproject({ ...newSubproject, start_date: e.target.value })}
            InputLabelProps={{ shrink: true }}
            sx={{ mt: 2 }}
            disabled={isCreating}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCreateModalOpen(false)} disabled={isCreating}>Cancel</Button>
          <Button 
            onClick={handleCreateSubproject} 
            variant="contained"
            disabled={isCreating}
            startIcon={isCreating ? <CircularProgress size={20} /> : null}
          >
            {isCreating ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubProjects;
