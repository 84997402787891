import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography } from '@mui/material';

const DynamicWorkDescription = ({ 
  templateText = '', // Provide default empty string
  initialValues = {}, 
  onChange,
  disabled = false 
}) => {
  const [inputValues, setInputValues] = useState(initialValues);
  
  useEffect(() => {
    setInputValues(initialValues);
  }, [templateText, initialValues]);

  const parseTemplate = (template) => {
    // Guard against null/undefined template
    if (!template) {
      return [{ type: 'text', content: '' }];
    }

    const segments = [];
    let currentText = '';
    let inputCount = 0;
    
    try {
      for (let i = 0; i < template.length; i++) {
        if (template[i] === '*' && template[i + 1] === '_') {
          if (currentText) {
            segments.push({ type: 'text', content: currentText });
            currentText = '';
          }
          segments.push({ type: 'input', id: inputCount });
          inputCount++;
          i++; // Skip the underscore
        } else {
          currentText += template[i];
        }
      }
      
      if (currentText) {
        segments.push({ type: 'text', content: currentText });
      }
      
      return segments;
    } catch (error) {
      console.error('Error parsing template:', error);
      return [{ type: 'text', content: template || '' }];
    }
  };

  const handleInputChange = (id, value) => {
    const newValues = { ...inputValues, [id]: value };
    setInputValues(newValues);
    if (onChange) {
      try {
        onChange(newValues);
      } catch (error) {
        console.error('Error in onChange handler:', error);
      }
    }
  };

  // Safely parse template with error handling
  const segments = React.useMemo(() => {
    try {
      return parseTemplate(templateText);
    } catch (error) {
      console.error('Error creating segments:', error);
      return [{ type: 'text', content: templateText || '' }];
    }
  }, [templateText]);

  return (
    <Box 
      className="dynamic-description-container" 
      sx={{ 
        width: '100%', 
        display: 'block', 
        whiteSpace: 'normal', 
        wordBreak: 'break-word',
        overflowWrap: 'break-word'
      }}
    >
      {segments.map((segment, index) => (
        segment.type === 'text' ? (
          <Typography 
            key={index} 
            component="span"
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
          >
            {segment.content}
          </Typography>
        ) : (
          <TextField
            key={index}
            size="small"
            value={inputValues[segment.id] || ''}
            onChange={(e) => handleInputChange(segment.id, e.target.value)}
            disabled={disabled}
            className="custom-text-field"
            multiline
            sx={{
              minWidth: '100px',
              margin: '0 4px',
              display: 'inline-flex'
            }}
          />
        )
      ))}
    </Box>
  );
};

export default DynamicWorkDescription;
